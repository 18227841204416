import * as React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { useStaticQuery, graphql } from "gatsby";
import get from "lodash/get";
import BackgroundImg from "../../../images/single-practice-background.png";

import "./about.less";

export default ({ data }) => {
  const subTitle = data.dataRaw.sub_title[0].text;
  const mobileImg = data.dataRaw.mobile_thumbnail.url;
  const desktopImg = data.dataRaw.desktop_thumbnail.url;
  const subHeading = data.dataRaw.sub_heading[0].text;
  const practiceDescription = data.dataRaw.practice_description;
  const uid = data.uid;

  const practiceAreaData = useStaticQuery(graphql`
    {
      allPrismicPracticeArea(sort: { order: ASC, fields: data___order }) {
        nodes {
          uid
          data {
            title
          }
        }
      }
    }
  `);

  const practiceAreas = get(
    practiceAreaData,
    "allPrismicPracticeArea.nodes",
    []
  );

  return (
    <section className="practice-about">
      <article className="practice-about-wrapper">
        <Container>
          <Row>
            <Col xs={12} md={4} className="practice-links">
              <div className="practice-links-wrapper">
                {practiceAreas.map((pl, idx) => {
                  return (
                    <Link
                      key={idx}
                      className={`single-link ${pl.uid === uid ? "bold" : ""}`}
                      to={`../${pl.uid}`}
                    >
                      {pl.data.title}
                    </Link>
                  );
                })}
              </div>
            </Col>
            <Col xs={12} md={8} className="detail-wrapper">
              <h3>{subTitle}</h3>
              <p className="sub-heading">{subHeading}</p>
              <img src={mobileImg} alt="" className="mobile-img" />
              <img src={desktopImg} alt="" className="desktop-img" />
              <div className="practice-description">
                {RichText.render(practiceDescription)}
              </div>
              {/* <div className="sub-sections">
                {subSection.map((ss, idx) => {
                    return (
                      <div className="single-sub-section" key={idx}>
                        <h6>{ss.sub_section_title[0].text}</h6>
                        {RichText.render(ss.sub_section_description)}
                      </div>
                    );
                })}
              </div> */}
            </Col>
          </Row>
        </Container>
        <div className="background-img-wrapper">
          <img src={BackgroundImg} alt="" />
        </div>
      </article>
    </section>
  );
};
