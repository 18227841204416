import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "gatsby";
import { Container } from "react-bootstrap";

import "./slider-carousel.less";

export default class SimpleSlider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const receivedData = this.props.data;
    const currentID = this.props.currID;
    const buttonLabel = this.props.label;
    const hide = this.props.hide;

    return (
      <section className={`other-practice-slider ${hide ? "hide" : ""}`}>
        <article className="other-practice-slider-wrapper">
          <Container className="sm-slider">
          <h2>Other Practice Areas</h2>
            <Slider
              swipeToSlide={true}
              slidesToShow={2}
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={6000}
              lazyLoad={true}
              cssEase={"ease-in-out"}
              >
              {receivedData.map((rd, idx) => {
                if (currentID === rd.uid) {
                  return null;
                } else {
                  return (
                    <div className="single-practice" key={idx}>
                      <img src={rd.data.mobile_thumbnail.url} alt="" />
                      <h4>{rd.data.title}</h4>
                      <p>{rd.data.sub_title.text}</p>
                      <Link to={`../${rd.uid}`} className="btn white-hover">
                        {buttonLabel}
                      </Link>
                    </div>
                  );
                }
              })}
            </Slider>
          </Container>
          <Container className="lg-slider">
            <h2>Other Practice Areas</h2>
            <Slider
              swipeToSlide={true}
              slidesToShow={3}
              focusOnSelect={true}
              autoplay={true}
              autoplaySpeed={6000}
              lazyLoad={true}
              cssEase={"ease-in-out"}
            >
              {receivedData.map((rd, idx) => {
                if (currentID === rd.uid) {
                  return null;
                } else {
                  return (
                    <div className="single-practice" key={idx}>
                      <img src={rd.data.mobile_thumbnail.url} alt="" />
                      <h4>{rd.data.title}</h4>
                      <p>{rd.data.sub_title.text}</p>
                      <Link to={`../${rd.uid}`} className="btn white-hover">
                        {buttonLabel}
                      </Link>
                    </div>
                  );
                }
              })}
            </Slider>
          </Container>
        </article>
      </section>
    );
  }
}
