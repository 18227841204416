import * as React from "react";
import { Accordion, Container } from "react-bootstrap";

import "./faq.less";

const FAQ = ({ data, hide }) => {
  const dataReceived = data.dataRaw;

  const faqTitle = dataReceived.faq_title[0].text;
  const faqGroup = dataReceived.faq_group;

  return (
    <section className={`faq ${hide ? "hide" : ""}`}>
      <article className="faq-wrapper">
        <h2 className="faq-title">{faqTitle}</h2>
        <Container>
          <div className="faq-accordian">
            <Accordion defaultActiveKey="0">
              {faqGroup.map((fg, idx) => {
                return (
                  <Accordion.Item
                    eventKey={`item_${idx}`}
                    key={idx}
                    className="single-faq"
                  >
                    <Accordion.Header className="question">
                      {fg.faq_question[0].text}
                    </Accordion.Header>
                    <Accordion.Body className="answer">
                      <p>{fg.faq_answer[0].text}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
        </Container>
      </article>
    </section>
  );
};

export default FAQ;
