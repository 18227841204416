import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Hero from "../components/Practices/Hero/Hero";
import About from "../components/Practices/About/About";
import PracticeCTA from "../components/PracticeCTA/PracticeCTA";
import FAQ from "../components/Practices/FAQ/FAQ";
import Results from "../components/Results/Results";
import Contact from "../components/Contact/Contact";
import Testimonials from "../components/Testimonials/Data";
import Slider from "../components/Practices/Slider/Slider";
import get from "lodash/get";

const BlogPost = ({ data, pageContext: { uid } }) => {
  const practiceArea = data.prismicPracticeArea;

  const faq = get(practiceArea, "dataRaw.faq_group", []);

  return (
    <Layout>
      <Hero data={practiceArea} />
      <About data={practiceArea} />
      <PracticeCTA hide={practiceArea.dataRaw.hide_cta} />
      <Testimonials hide={practiceArea.dataRaw.hide_testimonials} />
      <Results hide={practiceArea.dataRaw.hide_results} />
      {faq && faq.length > 0 && (
        <FAQ data={practiceArea} hide={practiceArea.dataRaw.hide_faq} />
      )}
      <Contact map={true} hide={practiceArea.dataRaw.hide_contact} />
      <Slider
        uid={uid}
        data={practiceArea}
        hide={practiceArea.dataRaw.hide_other_practices}
      />
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPost($uid: String) {
    prismicPracticeArea(uid: { eq: $uid }) {
      uid
      dataRaw
    }
  }
`;
