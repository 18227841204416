import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Testimonials from "./Testimonials";

export default ({ hide }) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicTestimonial {
          nodes {
            data {
              testimonials {
                testimony {
                  text
                }
                testimony_image {
                  url
                }
                testimony_name {
                  text
                }
                testimony_position {
                  text
                }
              }
              testimonial_sub_title {
                text
              }
              testimonial_title {
                text
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const passData = data.allPrismicTestimonial.nodes[0].data;
      // useEffect(() => {
      //   const script = document.createElement("script");
      //
      //   script.src = "https://apps.elfsight.com/p/platform.js";
      //   script.defer = true;
      //   script.async = false;
      //
      //   document.body.appendChild(script);
      // }, []);

      return <Testimonials data={passData} hide={hide} />;
    }}
  />
);
