import * as React from "react";
import { Container } from "react-bootstrap";
import Modal from "../../Modal/Modal";

import "./hero.less";

export default ({ data }) => {
  const dataReceived = data.dataRaw;
  const heroImg = dataReceived.cover_image.url;
  const heroTitle = dataReceived.hero_title[0].text;
  const heroDesc = dataReceived.hero_description[0].text;
  const btnText = dataReceived.hero_button_label[0].text;

  const [modalShow, setModalShow] = React.useState(false);

  return (
    <section className="practice-hero">
      <article className="practice-hero-container">
        <img
          src={heroImg}
          alt="practice Banner"
          className={"image-with-opacity"}
        />
        <Container className="hero-content">
          <h1>{heroTitle}</h1>
          <p>{heroDesc}</p>
          <button
            className="btn consult blue-hover"
            onClick={() => {
              setModalShow(true);
            }}
          >
            {btnText}
          </button>
          {modalShow && (
            <Modal show={modalShow} onHide={() => setModalShow(false)} />
          )}
        </Container>
      </article>
    </section>
  );
};
