import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import get from "lodash/get";
import SliderCarousel from './Slider-Carousel'

export default ({uid, hide}) => {
  const practiceAreaData = useStaticQuery(graphql`
    {
      allPrismicPracticeArea(sort: { order: ASC, fields: data___order }) {
        nodes {
          uid
          data {
            mobile_thumbnail {
              url
            }
            title
            sub_title {
              text
            }
          }
        }
      }
      allPrismicPracticeAreas {
        nodes {
          data {
            read_more_button_label {
              text
            }
          }
        }
      }
    }
  `);

  const practiceAreas = get(
    practiceAreaData,
    "allPrismicPracticeArea.nodes",
    []
  );

  const buttonLabel = practiceAreaData.allPrismicPracticeAreas.nodes[0].data.read_more_button_label.text;

  return <SliderCarousel data={practiceAreas} label={buttonLabel} currID={uid} hide={hide}/>
};
