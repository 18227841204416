import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "react-bootstrap";
import "./practice-cta.less";

export default ({ hide }) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicPracticeCta {
        nodes {
          data {
            cta_button_icon {
              url
            }
            cta_button_label {
              text
            }
            cta_button_number {
              text
            }
            cta_text {
              text
            }
          }
        }
      }
    }
  `);

  const callIcon = data.allPrismicPracticeCta.nodes[0].data.cta_button_icon.url;
  const ctaText = data.allPrismicPracticeCta.nodes[0].data.cta_text.text;
  const ctaBtnLabel =
    data.allPrismicPracticeCta.nodes[0].data.cta_button_label.text;
  const ctaNumber =
    data.allPrismicPracticeCta.nodes[0].data.cta_button_number.text;

  return (
    <section className={`practice-cta-section ${hide ? "hide" : ""} pb-5`}>
      <article className={""}>
        <Container className="practice-cta-wrapper">
          <p>{ctaText}</p>
          <div className="btn-container">
            <a href={`tel:+${ctaNumber}`} className="btn box-hover">
              <img src={callIcon} alt="" />
              {ctaBtnLabel}
            </a>
          </div>
        </Container>
      </article>
    </section>
  );
};
