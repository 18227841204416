import React, { Component } from "react";
import { Container, Row} from "react-bootstrap";

import "../HomePage/Testimonials/testimonial.less";

export default class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const data = this.props.data;
    const hide = this.props.hide;

    const testimonialTitle = data.testimonial_title.text;

    return (
      <section className={`testimonials ${hide ? "hide" : ""}`}>
        <article className="testimonials-wrapper">
          <Container>
            <h2 className="title">{testimonialTitle}</h2>
            <Row>
              {/* <Col sm={12} className="sub-title">
                <h3>{testimonialSubtitle}</h3>
              </Col> */}
              {/* <Col sm={12} className="carousel-one-wrapper">
                <div className="carousel-one sm">
                  <Slider
                    asNavFor={this.state.nav1}
                    ref={(slider) => (this.slider2 = slider)}
                    swipeToSlide={true}
                    slidesToShow={1}
                    focusOnSelect={true}
                    autoplay={true}
                    autoplaySpeed={6000}
                    lazyLoad={true}
                    cssEase={"ease-in-out"}
                  >
                    {testimonials.map((t, idx) => {
                      return (
                        <div key={idx} className="testimony-profile">
                          <img
                            src={t.testimony_image.url}
                            alt=""
                            className="testimony-img"
                          />
                          <h4>{t.testimony_name.text}</h4>
                          <p>{t.testimony_position.text}</p>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div className="carousel-one lg">
                  <Slider
                    asNavFor={this.state.nav1}
                    ref={(slider) => (this.slider2 = slider)}
                    swipeToSlide={true}
                    slidesToShow={3}
                    focusOnSelect={true}
                    autoplay={true}
                    autoplaySpeed={6000}
                    lazyLoad={true}
                    cssEase={"ease-in-out"}
                    centerMode={true}
                  >
                    {testimonials.map((t, idx) => {
                      return (
                        <div key={idx} className="testimony-profile">
                          <img
                            src={t.testimony_image.url}
                            alt=""
                            className="testimony-img"
                          />
                          <h4>{t.testimony_name.text}</h4>
                          <p>{t.testimony_position.text}</p>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </Col> */}
              {/* <Col sm={12} className="carousel-two-wrapper">
                <div className="carousel-two">
                  <Slider
                    asNavFor={this.state.nav2}
                    ref={(slider) => (this.slider1 = slider)}
                    slidesToShow={1}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    swipeToSlide={true}
                    focusOnSelect={true}
                    autoplay={true}
                    autoplaySpeed={6000}
                    lazyLoad={true}
                    cssEase={"ease-in-out"}
                  >
                    {testimonials.map((t, idx) => {
                      return (
                        <div key={idx} className="testimony">
                          <p>{t.testimony.text}</p>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </Col> */}
              <div className="elfsight-app-6de39d76-0346-406e-89ca-3fd5af2b2c9f"></div>
            </Row>
          </Container>
        </article>
      </section>
    );
  }
}
